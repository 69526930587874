import React from 'react';
import '../styles/GamingAnimation.css'
const GamingAnimation = () => {

    return(
        <div class="pacman">
        <div class="pacman-top"></div>
        <div class="pacman-bottom"></div>
        {/* <div class="feed"></div> */}
        </div>
    )
}

export default GamingAnimation;